import { javascript } from '@api/index'
import { useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type OrganisationStoreType = ReturnType<typeof useOrganisationStore>
export const allOrganisationStores = ref<Array<OrganisationStoreType>>([])

type OrganisationDataType = Backend.Models.Organisation & {
  settings_for_frontend: Backend.Models.Organisation['settings']
}

export const createOrganisationStore = (uniqueId: string | number) => {
  const organisationStore = defineStore(
    'organisation-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<OrganisationDataType>(
        javascript.loggedOrganisation
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof OrganisationDataType> = []
      ) => {
        return hydration.hydrate({ id }, fields)
      }
      allOrganisationStores.value.push(
        organisationStore as unknown as OrganisationStoreType
      )

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        defaultParticipantRights: hydration.hydratedComputed(
          'settings_for_frontend',
          (settings) => {
            return {
              edit: settings.new_templates_to_generate_documents_in_view_mode
                ? 'Data'
                : 'All'
            }
          }
        )
      }
    }
  )
  return organisationStore
}

export const useOrganisationStore = createOrganisationStore('current')
