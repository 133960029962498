import { javascript } from '@api/index'
import { removeUndefined } from '@avvoka/shared'
import { StoreMode, useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useOrganisationStore } from './organisation.store'

type DocxSettings = CamelCasedProps<
  Backend.Models.TemplateVersion['docx_settings']
>
type PartyRights = {
  allowed_email_domains: string
  follow_user_profiles_rights: string | 'undefined'
  edit: Backend.Models.EditRights
  approve: Backend.Models.ApproveRights
  invite: Backend.Models.InviteRights
  sign: Backend.Models.SignRights
  default_email: string
  default_name: string
  default_role: boolean
  lock_email: boolean
  manage_comments: boolean
  force_add: string
}

export type TemplateVersionStoreType = ReturnType<
  typeof useTemplateVersionStore
>
export const allTemplateVersionStores = ref<Array<TemplateVersionStoreType>>([])

type TemplateVersionDataType = Backend.Models.TemplateVersion & {
  avv_participants_raw: [
    Backend.Models.PartyRole,
    string,
    Backend.Models.PartyName,
    PartyRights
  ][]
}

export type Parties = TemplateVersionDataType['avv_participants_raw']

export const createTemplateVersionStore = (uniqueId: string | number) => {
  const templateVersionStore = defineStore(
    'template-version-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<TemplateVersionDataType>(
        javascript.templateVersion
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof TemplateVersionDataType> = []
      ) => {
        return hydration.hydrate({ id }, fields)
      }
      allTemplateVersionStores.value.push(
        templateVersionStore as unknown as TemplateVersionStoreType
      )
      const styles = hydration.hydratedComputed('docx_settings', (settings) => {
        return removeUndefined({
          formats: settings.formats,
          docxNamesByOrigin: settings.docx_names_by_origin,
          stylesRelations: settings.stylesRelations,
          inactiveFormats: settings.inactiveFormats,
          metadata: settings.metadata,
          version: settings.version,
          dataDocxRef: settings['data-docx-ref']
        } satisfies DocxSettings) as DocxSettings
      })

      const defaultStyle = computed<
        Backend.Models.TemplateVersion.StyleFormat[string] & { key: string }
      >(() => {
        const entries = Object.entries(styles.value?.formats ?? {})
        const result =
          entries.find(([, value]) => value['default'] === true) ??
          entries.find(([key]) => key === 'Normal')
        return {
          key: result?.[0] ?? '',
          ...(result?.[1] ?? {})
        } as const
      })

      watch(
        hydration.hydratedData,
        (data) => {
          const docxSettings = data?.docx_settings
          if (docxSettings == null) return

          if (docxSettings.formats == null) {
            docxSettings.formats = {}
          }
          if (docxSettings.inactiveFormats == null) {
            docxSettings.inactiveFormats = {}
          }
        },
        { immediate: true }
      )

      const $default = () => {
        const defaultPartyName = localizeText('general.party') + ' A'
        const defaultRoleName = localizeText('general.role')
        const defaultRights = {
          approve: 'All',
          contact_group: '',
          custom_email: '0',
          default_email: '',
          edit: 'All',
          force_add: 'send',
          invite: 'All',
          sign: 'Yes',
          allowed_email_domains: '',
          manage_comments: true,
          ...useOrganisationStore().defaultParticipantRights
        }
        hydration.hydratedData.value = {
          id: -1,
          name: '',
          avv_participants_raw: [
            [defaultRoleName, '', defaultPartyName, defaultRights]
          ],
          docx_settings: {
            docx_names_by_origin: {},
            stylesRelations: {},
            inactiveFormats: {},
            formats: {
              Normal: {
                definition: {
                  fontSize: { size: '11' },
                  font: { font: 'arial' }
                },
                name: 'Normal',
                default: true
              }
            },
            version: 1,
            metadata: {}
          }
        } as unknown as TemplateVersionDataType
        hydration.hydrated.value = true
        hydration.storeMode.value = StoreMode.NewData
      }

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        /** @deprecated use docxSettings */
        styles,
        stylesOrSetDefault: computed<DocxSettings>(() => {
          let result: DocxSettings = {
            formats: {},
            docxNamesByOrigin: {},
            stylesRelations: {},
            inactiveFormats: {},
            metadata: {},
            version: 1,
            dataDocxRef: ''
          }

          try {
            result = {
              ...result,
              ...styles.value
            }
          } catch (_) {}

          hydration.hydratedData.value!.docx_settings = {
            formats: result.formats,
            docx_names_by_origin: result.docxNamesByOrigin ?? {},
            stylesRelations: result.stylesRelations ?? {},
            inactiveFormats: result.inactiveFormats ?? {},
            metadata: result.metadata,
            version: result.version,
            'data-docx-ref': result.dataDocxRef
          }

          return result
        }),
        docxSettings: styles,
        defaultStyle: defaultStyle,
        setDefaultStyle(key: string) {
          delete hydration.hydratedData.value!.docx_settings.formats[
            defaultStyle.value.key
          ].default
          hydration.hydratedData.value!.docx_settings.formats[key].default =
            true
        },
        parties: hydration.hydratedComputed('avv_participants_raw'),
        $default,
        setDocxSettings(
          settings: Backend.Models.TemplateVersion['docx_settings']
        ) {
          if (hydration.hydratedData) {
            hydration.hydratedData.value!.docx_settings = settings
          }
        }
      }
    }
  )
  return templateVersionStore
}

export const useTemplateVersionStore = createTemplateVersionStore('current')
